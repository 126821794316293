import Sortable from "stimulus-sortable"

export default class extends Sortable {
  connect() {
    super.connect()

  }

  // You can override the `end` method here.
  end () {
    console.log('end')
    console.log(this.element.children)

    let el = this.element
    let railsSortable = [];

    for(let i = 0; i < el.children.length; i++) {
        let providerFacilityTypeId = el.children[i].dataset['providerFacilityTypeId'];
        let token = el.children[i].dataset['idTag'];
        railsSortable.push({"klass": "ProviderFacilityVideoType", id: providerFacilityTypeId, token: token})
    }

    const csrfToken = document.querySelector("[name='csrf-token']").content

    fetch('/provider_facilities/sort', {
        method: 'POST',
        headers: {
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({"rails_sortable": railsSortable})
    })
  }


}