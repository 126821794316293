import { Controller } from "@hotwired/stimulus";
import JSONEditor from 'jsoneditor';

export default class extends Controller {
    static targets = [ "body" ]
    static values = {
        editorMode: Array, default: ['tree']
    }

    connect() {
        const container = document.getElementById("json_body_editor")

        const options = {
            modes: this.editorModeValue,
            onChangeText: (jsonString) => {
                this.setBodyPolicy(jsonString)
            }
        }
        const editor = new JSONEditor(container, options)
        editor.set(JSON.parse(this.getBodyPolicy())) 
  }

  getBodyPolicy() {
    return this.bodyTarget.value
  }

  setBodyPolicy(value) {
    this.bodyTarget.value = value
  }

}
