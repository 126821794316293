import { Application } from "@hotwired/stimulus";
import jquery from 'jquery';
import 'popper.js'
import 'bootstrap'
import 'daemonite-material';
import "chartkick/chart.js";
import Rails from "@rails/ujs"

Rails.start()
const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application
window.jQuery = jquery
window.$ = jquery


export { application }
