import { Controller } from "@hotwired/stimulus"
import axios from "axios";

// Connects to data-controller="move-tablet"
export default class extends Controller {

    static targets = [ "tabletName", "tabletForm", "facilitySelect"]
    static tabletId = ""
    static tabletName = ""

    openModal(e) {
        this.tabletName = e.target.dataset.tabletName
        this.tabletId = e.target.dataset.tabletId

        const tabletNameEl = this.tabletNameTarget
        const tabletFormEl = this.tabletFormTarget

        tabletFormEl.action = "/tablets/" + this.tabletId + "/move"
        tabletNameEl.innerHTML = "Move Tablet " + this.tabletName
    }

    changeProvider = async(e) => {

        const facilitySelectEl = this.facilitySelectTarget
        let providerId = e.target.value;
        let facilitySelectHtml = await axios.get("/providers/" + providerId + "/facilities")
        facilitySelectEl.innerHTML = facilitySelectHtml.data
    }
}
