import { Controller } from "@hotwired/stimulus";


export default class extends Controller {

    static targets = ["videoTypeCategories"]

    connect() { 
        
    }

    async typeChange(e) {
        const el = e.target
        const videoTypeId = el.value;
        let categoryDiv = null;
        this.videoTypeCategoriesTargets.forEach( target => {
            const id = target.dataset.id;
            if(id == videoTypeId) {
                categoryDiv = target;
            }
        })
        
        if(el.checked) {
            $(categoryDiv).collapse('show')
        } else {        
            $('.video-category__checkbox').prop('checked', false);
            $(categoryDiv).collapse('hide')
        }
        
    }

}
