import { Controller } from "@hotwired/stimulus"
import JSONEditor from 'jsoneditor';

// Connects to data-controller="captions"
export default class extends Controller {
    static targets = [ "body" ]
    static values = {
        editorMode: Array, default: ['tree'],
        body: String
    }

    connect() {
        const container = document.getElementById("captions_body_display")

        const options = {
            modes: this.editorModeValue,
        }

        const editor = new JSONEditor(container, options)
        editor.set(JSON.parse(this.bodyValue)) 
        editor.expandAll();
  }

}
