import { Controller } from "@hotwired/stimulus";
import ChartKick from 'chartkick';

export default class extends Controller {
    static targets = [ 
        "startDate", 
        "endDate", 
        "providerId", 
        "deviceType",
        "total",
        "dailyAverage" ]

    get chart() {
        return Chartkick.charts["video_plays"]
    }
    connect() {
        $('[id*="video_plays_report_start_date"]')
            .on('mousedown', function(event) { event.preventDefault(); })
            .pickdate({
                format: 'mm-dd-yyyy',
                selectMonths: true,
                selectYears: true,
            }
        );
        $('[id*="video_plays_report_end_date"]')
            .on('mousedown', function(event) { event.preventDefault(); })
            .pickdate({
                format: 'mm-dd-yyyy',
                selectMonths: true,
                selectYears: true,
            }
        );
        this.update()
    }

    update() {
        fetch(`/video_plays?start_date=${this.startDateTarget.value}&end_date=${this.endDateTarget.value}&provider_id=${this.providerIdTarget.value}&device=${this.deviceTypeTarget.value}`, 
        ).then(async (res) => {    
            let data = await res.json() 
            this.chart.updateData(data.chart_data)
            this.totalTarget.textContent = data.total
            this.dailyAverageTarget.textContent = data.average
        });
    }

}
