import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="logs"
export default class extends Controller {
  connect() {
    $('#log_start_date')
        .on('mousedown', function(event) { event.preventDefault(); })
        .pickdate({
            format: 'mm-dd-yyyy',
            onClose: function(){
                //$('#log_date_form').submit();
                console.log("close")
            }
        });

    $('#log_end_date')
        .on('mousedown', function(event) { event.preventDefault(); })
        .pickdate({
            format: 'mm-dd-yyyy',
            onClose: function(){
                //$('#log_date_form').submit();
                console.log("close")
            }
        });
  }
}
