import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tablet-location"
export default class extends Controller {

    static values = {
        latitude: String,
        longitude: String
    }

  connect() {

      
    const latitude = this.latitudeValue;
    const longitude = this.longitudeValue;


        const latlng = new google.maps.LatLng(latitude, longitude);
        const map = new google.maps.Map(document.getElementById("map"), {
          center: latlng,
          zoom: 13,
        });


        const geocoder = new google.maps.Geocoder();
        const infowindow = new google.maps.InfoWindow();
        geocoder
            .geocode({ location: latlng })
            .then((response) => {
                infowindow.setContent(response.results[0].formatted_address);
                infowindow.open(map, marker);
        })
        const marker = new google.maps.Marker({
            position: latlng,
            map: map,
          });


  }
}
