import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ 
        "startDate", 
        "endDate", 
        "total",
        "exportButton",
        "dailyAverage" ]


    connect() {
        let that = this;
        $('[id*="form_submissions_report_start_date"]')
            .on('mousedown', function(event) { event.preventDefault(); })
            .pickdate({
                format: 'mm-dd-yyyy',
                selectMonths: true,
                selectYears: true,
                onClose: function() {
                    that.updateExportButton()
                },
            }
        );

        console.log('test')
        $('[id*="form_submissions_report_end_date"]')
            .on('mousedown', function(event) { event.preventDefault(); })
            .pickdate({
                format: 'mm-dd-yyyy',
                selectMonths: true,
                selectYears: true,
                onClose: function() {
                    that.updateExportButton() 
                },
            }
        );
        //this.update()
    }

    updateExportButton(event) {
        let startDate = this.targets.find("startDate").value
        let endDate = this.targets.find("endDate").value

        let exportButton = this.targets.find("exportButton")
        const currentUrl = exportButton.href;
        const lastSlashIndex = currentUrl.lastIndexOf("/");
        const baseUrl = currentUrl.substring(0, lastSlashIndex);
        const newUrl = `${baseUrl}/export?start_date=${startDate}&end_date=${endDate}`;
        exportButton.href = newUrl;
      }


}
