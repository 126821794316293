import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tablet-form"
export default class extends Controller {


  connect() {
    $('[id*="tablet_end_trial_date"]')
            .on('mousedown', function(event) { event.preventDefault(); })
            .pickdate({
                format: 'yyyy-mm-dd',
                selectMonths: true,
                selectYears: true,
            }
        );
      
  }
}
